import React, { Suspense, lazy, useState } from "react";
import { CirclesWithBar } from "react-loader-spinner";

import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const AssestsTracking = React.lazy(() =>
  import("./pages/services/gps-tracking/AssestsTracking")
);
const FleetManagement = React.lazy(() =>
  import("./pages/services/gps-tracking/FleetManagement")
);
const GpsTracking = React.lazy(() =>
  import("./pages/services/gps-tracking/GpsTracking")
);
const TrailerTracking = React.lazy(() =>
  import("././pages/services/gps-tracking/TrailerTracking")
);
const AiDashCams = React.lazy(() => import("./pages/services/AiDashCams"));
const Eld = React.lazy(() => import("./pages/services/Eld"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Career = React.lazy(() => import("./pages/Career"));
const SocialMediaDesc = React.lazy(() =>
  import("./pages/SocialMediaDescription")
);
const Construction = React.lazy(() =>
  import("./pages/Industries-we-serve/Construction")
);
const PackageDelivery = React.lazy(() =>
  import("./pages/Industries-we-serve/PackageDelivery")
);
const Transists = React.lazy(() =>
  import("./pages/Industries-we-serve/Transists")
);
const Trucking = React.lazy(() =>
  import("./pages/Industries-we-serve/Trucking")
);
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const RequestADemo = React.lazy(() => import("./pages/RequestADemo"));
const GpsBlogs = React.lazy(() => import("./pages/blogs-details/GpsBlogs"));
const EldBlogs = React.lazy(() => import("./pages/blogs-details/EldBlogs"));
const AiDashCamsBlogs = React.lazy(() =>
  import("./pages/blogs-details/AiDashCamsBlogs")
);
const ConstructionBlogs = React.lazy(() =>
  import("./pages/blogs-details/ConstructionBlogs")
);
const FleetManagementBlogs = React.lazy(() =>
  import("./pages/blogs-details/FleetManagementBlogs")
);
const LogisticsBusiness = React.lazy(() =>
  import("./pages/blogs-details/LogisticsBusiness")
);
const Faq = React.lazy(() => import("./pages/Faqs"));
const SocialMediaPost = React.lazy(() => import("./pages/SocialMediaPost"));
const Alerts = React.lazy(() => import("./pages/security/Alerts"));
const AccessControl = React.lazy(() =>
  import("./pages/security/AccessControl")
);
const Surveillance = React.lazy(() => import("./pages/security/Surveillance"));
const AlarmMonitoring = React.lazy(() =>
  import("./pages/security/AlarmMonitoring")
);
const Security = React.lazy(() => import("./pages/security/Security"));
const ItManagement = React.lazy(() =>
  import("././pages/services/ItManagement")
);
const AudioVideo = React.lazy(() => import("././pages/services/AudioVideo"));
function App() {
  return (
    <Suspense
      fallback={
        <div className="loader">
          {/* <div className="spinner"></div> */}
          <CirclesWithBar
            height="80"
            width="80"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            outerCircleColor="#18206f"
            innerCircleColor="#18206f"
            barColor="#18206f"
            ariaLabel="circles-with-bar-loading"
          />
        </div>
      }
    >
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/assests-tracking" element={<AssestsTracking />} />
        <Route path="/Fleet-management" element={<FleetManagement />} />
        <Route path="/gps-tracking" element={<GpsTracking />} />
        <Route path="/trailer-tracking" element={<TrailerTracking />} />
        <Route path="/ai-dash-cams" element={<AiDashCams />} />
        <Route path="/eld" element={<Eld />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/career" element={<Career />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/package-delivery" element={<PackageDelivery />} />
        <Route path="/transists" element={<Transists />} />
        <Route path="/trucking" element={<Trucking />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/request-demo" element={<RequestADemo />} />
        <Route path="/gps-blogs-detail" element={<GpsBlogs />} />
        <Route path="/eld-blogs-detail" element={<EldBlogs />} />
        <Route
          path="/ai-dash-cams-blogs-detail"
          element={<AiDashCamsBlogs />}
        />
        <Route
          path="/construction-blogs-detail"
          element={<ConstructionBlogs />}
        />
        <Route
          path="/fleet-management-blogs-detail"
          element={<FleetManagementBlogs />}
        />
        <Route
          path="/logistics-business-blogs-detail"
          element={<LogisticsBusiness />}
        />
        <Route path="/faq" element={<Faq />} />
        <Route path="/social-media-post" element={<SocialMediaPost />} />
        <Route path="/social-media-desc" element={<SocialMediaDesc />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/access-control" element={<AccessControl />} />
        <Route path="/surveillance" element={<Surveillance />} />
        <Route path="/alarm-monitoring" element={<AlarmMonitoring />} />
        <Route path="/it-management" element={<ItManagement />} />
        <Route path="/security" element={<Security />} />
        <Route path="/audio-video" element={<AudioVideo />} />
      </Routes>
      <Footer />
    </Suspense>
  );
}

export default App;
