import React from "react";
import { useState, useEffect } from "react";
export default function ScrollToTop() {
  const [scrollIsVisible, setscrollIsVisible] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", showschroll);
    return () => window.removeEventListener("scroll", showschroll);
  }, []);
  const showschroll = () => {
    let heightToHideFrom = 40;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      scrollIsVisible && setscrollIsVisible(false);
    } else {
      setscrollIsVisible(true);
    }
  };
  const linkPageScroll = () => {
    window.scrollTo({ top: 0 });
  };
  return (
    <>
      {scrollIsVisible ? (
        <div className="d-none"></div>
      ) : (
        <div className="scrollShow" onClick={linkPageScroll}>
          <i className="fas fa-angle-up moveScroll-icon"></i>
        </div>
      )}
    </>
  );
}
