import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { linkPageScroll } from "../../shared/ClickScroll";
import topBannerImages from "../../images/Dark_Plane.jpg";


import React from "react";
export default function Header() {
  const navigate = useNavigate();

  const [toggleIcon, setToggleIcon] = useState(false);

  const [scrollIsVisible, setscrollIsVisible] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", showschroll);
    return () => window.removeEventListener("scroll", showschroll);
  }, []);
  const showschroll = () => {
    let heightToHideFrom = 40;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (window.scrollY > 200) {
      document.getElementById("navbar_top").classList.add("fixed-top");
      // add padding top to show content behind navbar
      let navbar_height = document.querySelector(".navbar").offsetHeight;
      document.body.style.paddingTop = navbar_height + "px";
    } else {
      document.getElementById("navbar_top").classList.remove("fixed-top");
      // remove padding top from body
      document.body.style.paddingTop = "0";
    }
  };

  const itManagement = () => {
    linkPageScroll();
    navigate("/it-management", {
      state: [
        { bannerTitle: "IT Management" },
        {
          bannerDesc:
            "We provide full IT administration services, including software installation and updates, email management, network and server setup and more.",
        },
      ],
    });
  };

  return (
    <>
      {scrollIsVisible ? (
        <div className="d-none"></div>
      ) : (
        <div className="scrollShow" onClick={linkPageScroll}>
          <i className="fas fa-angle-up moveScroll-icon"></i>
        </div>
      )}

      <div className="container">
        <div className="row">
          <div className="top-header mt-1">
            <div className="left-item">
              <div>
                <span>
                  <i
                    className="fas fa-envelope"
                    style={{ color: "#18206f" }}
                  ></i>
                </span>{" "}
                <span>
                  <a href="mailto:Info@obitsolution.com">
                    Info@obitsolution.com
                  </a>
                </span>{" "}
                &nbsp;&nbsp;
              </div>
              {/* <div>
                <span>
                  <i
                    class="fas fa-envelope-open-text"
                    style={{ color: "#18206f" }}
                  ></i>
                </span>{" "}
                <span>Newsletter</span> &nbsp;&nbsp;
              </div> */}
              <div>
                <span>
                  <i
                    className="fas fa-user-alt"
                    style={{ color: "#18206f" }}
                  ></i>
                </span>{" "}
                <span>
                  <Link to="/career" onClick={linkPageScroll}>
                    Career
                  </Link>
                </span>{" "}
                &nbsp;&nbsp;
              </div>
            </div>
            <div className="right-item1">
              <div className="social-media-section">
                {/* <div>
                  <a
                    href="https://www.facebook.com/profile.php?id=100087972435127"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f topRight-icon-style"></i>
                  </a>
                </div> */}

                <div>
                  <div className="twitter-hover btn--hover-icon">

                  {/* Social media */}

                    {/* <i className="material-icons"> */}
                    {/* <div className="btn-group hover_drop_down">
                      <i className="fab fa-instagram topRight-icon-style"></i>
                      <ul
                        className="dropdown-menu top-insta-dropdown-style pb-3"
                        role="menu"
                      >
                        <li className="nav-item dropdown">
                          <a
                            href="https://instagram.com/obit_solutions?igshid=MzRlODBiNWFlZA"
                            target="_blank"
                            className=""
                          >
                            Obit Solutions &nbsp;&nbsp;
                          </a>
                        </li>
                        <li className="nav-item dropdown">
                          <a
                            href="https://instagram.com/obit_security_solutions?igshid=MzRlODBiNWFlZA"
                            target="_blank"
                            className=""
                          >
                            Obit Security Solutions &nbsp;&nbsp;
                          </a>
                        </li>
                      </ul>
                    </div> */}
                    {/* </i> */}
                    {/* <span>
                      <a
                        title="Obit Solution Instagram"
                        href="https://www.instagram.com/obit_solutions/"
                        target="_blank"
                      >
                        <i class="fab fa-instagram topRight-icon-style"></i>
                      </a>
                    </span>
                    <span>
                      <a
                        title="Obit Solution"
                        href="https://www.instagram.com/obit_solutions/"
                        target="_blank"
                      >
                        <i class="fab fa-instagram topRight-icon-style"></i>
                      </a>
                    </span> */}
                  </div>

                  {/* <a
                    href="https://www.instagram.com/obit_solutions/"
                    target="blank"
                  >
                    <i class="fab fa-instagram topRight-icon-style"></i>
                  </a> */}
                </div>
                {/* <div>
                  <a
                    href="https://www.linkedin.com/in/obit-solutions-0a840b259/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in topRight-icon-style"></i>
                  </a>
                </div> */}

                {/* <div>
                  <i className="fab fa-twitter topRight-icon-style"></i>
                </div> */}
                {/* <div>
                  <i className="fab fa-youtube topRight-icon-style"></i>
                </div> */}
                <div style={{ display: "none" }}>
                  <i className="fab fa-whatsapp topRight-icon-style"></i>
                </div>
                <span className="topHeaderBtn">
                  <Link to="/request-demo">
                    <button>
                      {" "}
                      <i className="fas fa-calculator "></i>
                      <span>&nbsp;&nbsp;Get A Quote</span>{" "}
                    </button>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      {/* <div className="container"></div> */}

      <div className="container-fluid p-0 mainHeader btClear">
        <nav id="navbar_top" className="navbar navbar-expand-lg">
          <div className="container">
            <Link className="navbar-brand" to="/" onClick={linkPageScroll}>
              <img
                src="./assets/images/obit-new.png"
                alt=""
                style={{ width: "150px" }}
                className="img-fluid"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#main_nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                className="navbar-toggler-icon"
                onClick={() => setToggleIcon(!toggleIcon)}
              >
                <i
                  className={`fas text-white fs-1 ${
                    toggleIcon == false ? " fa-stream" : " fa-xmark"
                  }`}
                ></i>
              </span>
            </button>

            <div className="collapse navbar-collapse" id="main_nav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link cool-link active"
                    to="/"
                    onClick={linkPageScroll}
                  >
                    {" "}
                    Home{" "}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link cool-link"
                    data-bs-toggle="dropdown"
                    to=""
                  >
                    Pages
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-right">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/about-us"
                        onClick={linkPageScroll}
                      >
                        {" "}
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/contact-us"
                        onClick={linkPageScroll}
                      >
                        Contact Us
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to="/faq"
                        onClick={linkPageScroll}
                      >
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/social-media-post"
                        onClick={linkPageScroll}
                      >
                        Social Media Post
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link cool-link"
                    data-bs-toggle="dropdown"
                    to=""
                  >
                    Services
                  </Link>
                  <ul className="dropdown-menu">
                    <li className="dropdown-submenu">
                      <Link to="" className="dropdown-item dropdown-toggle">
                        GPS Tracking
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/gps-tracking"
                            onClick={linkPageScroll}
                          >
                            {" "}
                            GPS Tracking
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/trailer-tracking"
                            onClick={linkPageScroll}
                          >
                            Trailer Tracking
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="dropdown-item"
                            to="/assests-tracking"
                            onClick={linkPageScroll}
                          >
                            Assests Tracking
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/fleet-management"
                            onClick={linkPageScroll}
                          >
                            Fleet Management
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/ai-dash-cams"
                        onClick={linkPageScroll}
                      >
                        AI Dash Cams
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="dropdown-item"
                        to="/eld"
                        onClick={linkPageScroll}
                      >
                        ELD
                      </Link>
                    </li> */}
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={itManagement}
                        style={{ cursor: "pointer" }}
                      >
                        IT Management
                      </span>
                    </li>
                    <li className="dropdown-submenu">
                      <Link
                        to=""
                        className="dropdown-item"
                        onClick={linkPageScroll}
                      >
                        Security
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/security"
                            onClick={linkPageScroll}
                          >
                            Security
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/alarm-monitoring"
                            onClick={linkPageScroll}
                          >
                            Alarm Monitoring
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="dropdown-item"
                            to="/surveillance"
                            onClick={linkPageScroll}
                          >
                            Surveillance
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/access-control"
                            onClick={linkPageScroll}
                          >
                            Access Control
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/audio-video"
                        onClick={linkPageScroll}
                      >
                        Audio & Video
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link cool-link"
                    data-bs-toggle="dropdown"
                    to=""
                  >
                    Industries We Serve
                  </Link>
                  <ul className="dropdown-menu dropdown-menu-right">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/trucking"
                        onClick={linkPageScroll}
                      >
                        {" "}
                        Trucking
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/construction"
                        onClick={linkPageScroll}
                      >
                        Construction
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="dropdown-item"
                        to="/package-delivery"
                        onClick={linkPageScroll}
                      >
                        Package Delivery
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/transists"
                        onClick={linkPageScroll}
                      >
                        Transists
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link cool-link"
                    to="/blogs"
                    onClick={linkPageScroll}
                  >
                    Blogs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link cool-link"
                    to="http://dashboard.obitsolution.com"
                    onClick={linkPageScroll}
                  >
                    Login{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <nav class="navbar navbar-expand-sm fixed-top navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" href="#">Brand</a>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbar1">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar1">
            <ul class="navbar-nav">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item dropdown">
                    <a href="#" id="menu" data-bs-toggle="dropdown" class="nav-link dropdown-toggle" data-bs-display="static">Dropdown</a>
                    <ul class="dropdown-menu">
                        <li class="dropdown-submenu">
                            <a href="#" data-bs-toggle="dropdown" class="dropdown-item dropdown-toggle">Submenu 1</a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a href="#" class="dropdown-item">Item 1</a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item">Item 2</a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item">Item 3</a>
                                </li>
                            </ul>
                        </li>
                        <li class="dropdown-submenu">
                            <a href="#" data-bs-toggle="dropdown" class="dropdown-item dropdown-toggle">Submenu 2</a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a href="#" class="dropdown-item">Item a</a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item">Item b</a>
                                </li>
                                <li>
                                    <a href="#" class="dropdown-item">Item c</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#">Link</a>
                </li>
            </ul>
        </div>
    </div>
</nav> */}

        <div className="btCurveHeader">
          <div className="btCurveHolder">
            <div className="btCurveLeftHolder">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="80px"
                height="20px"
                viewBox="0 0 80 20"
                enableBackground="new 0 0 80 20"
                xmlSpace="preserve"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M 81 20 c 0 0 -16 0 -30 0 c -30 0 -23 -15 -50 -15 l 0 -5 L 81 0 L 81 20 Z"
                  className="btCurveLeft"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M 81 -1 L 31 -1 c 27 -1 21 15 51 16 C 82 3 82 -1 82 -1 Z"
                  className="btCurveLeftFill"
                />
              </svg>
            </div>
            <div className="btCurveRightHolder">
              <svg
                version="1.1"
                id="Layer_2"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="80px"
                height="20px"
                viewBox="0 0 80 20"
                enableBackground="new 0 0 80 20"
                xmlSpace="preserve"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M 0 20 c 0 0 16 0 30 0 c 30 0 23 -15 50 -15 l 0 -5 L 0 0 L 0 20 Z"
                  className="btCurveRight"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M -1 -1 L 50 -1 C 23 -1 30 15 -1 15 C -1 3 -1 -1 -1 -1 Z"
                  className="btCurveRightFill"
                />
              </svg>
            </div>
            <div className="btSiteHeaderCurveSleeve"></div>
          </div>
        </div>
      </div>
    </>
  );
}
