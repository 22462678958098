export const socialMediaItem = [
    {
      id: 1,
      name: "Facebook",
      link: "https://www.facebook.com/profile.php?id=100087972435127",
      icon: "fab fa-facebook-f",
    },
    {
      id: 2,
      name: "Instagram",
      link: "https://www.instagram.com/obit_solutions/",
      icon: "fab fa-instagram",
    },
    {
      id: 3,
      name: "Linkedin",
      link: "https://www.linkedin.com/in/obit-solutions-0a840b259/",
      icon: "fab fa-linkedin-in",
    },
    {
      id: 4,
      name: "Twitter",
      link: "",
      icon: "fab fa-twitter",
    },
    {
      id: 5,
      name: "Youtube",
      link: "",
      icon: "fab fa-youtube",
    },
  ];

export const navLink = [
   {
    id : 1,
    title : "Contact",
    linkList : [
        {   id : 1,
            linkUrl : "/",
            linkName : "Home",
        },
        {   id : 2,
            linkUrl : "/about-us",
            linkName : "About Us",
        },
        {   id : 3,
            linkUrl : "/contact-us",
            linkName : "Contact Us",
        },
        {   id : 4,
            linkUrl : "/blogs",
            linkName : "Blogs",
        },
    ]
   },
   {
    id : 2,
    title : "Services",
    linkList : [
        {   id : 1,
            linkUrl : "/gps-tracking",
            linkName : " GPS Tracking",
        },
        {   id : 2,
            linkUrl : "/ai-dash-cams",
            linkName : "AI Dash Cams",
        },
        {   id : 3,
            linkUrl : "/eld",
            linkName : "Eld",
        },
        {   id : 4,
            linkUrl : "/it-management",
            linkName : "IT Management",
        },
        {   id : 5,
            linkUrl : "/security",
            linkName : "Security",
        },
        {   id : 6,
            linkUrl : "/audio-video",
            linkName : "Audio & Video",
        }
    ]
   },
   {
    id : 3,
    title : "Industries",
    linkList : [
        {   id : 1,
            linkUrl : "/trucking",
            linkName : "Trucking",
        },
        {   id : 2,
            linkUrl : "/construction",
            linkName : "Constructions",
        },
        {   id : 3,
            linkUrl : "/package-delivery",
            linkName : "Package Delivery",
        },
        {   id : 4,
            linkUrl : "/transists",
            linkName : "Transists",
        },
    ]
   },

]