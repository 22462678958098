import { Link } from "react-router-dom";
import React from "react";
import ScrollToTop from "../scroll-to-top/ScrollToTop";
import { socialMediaItem } from "../../shared/footer-items/FooterItem";
import { navLink } from "../../shared/footer-items/FooterItem";
export default function Footer() {
  const settings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: false,
    },
    particle: {
      particleCount: 20,
      color: "#e0eaf5",
      minSize: 2,
      maxSize: 5,
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 1,
      maxSpeed: 3,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 3000,
    },
  };

  const linkPageScroll = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <section className="boldSection gutter btSiteFooterCurve ">
        <div className="port">
          <div className="btCurveLeftHolder">
            <svg
              version="1.1"
              id="Layer_3"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="50px"
              height="14px"
              viewBox="0 0 50 14"
              enableBackground="new 0 0 50 14"
              xmlSpace="preserve"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 0 14 C 27 15 20 0 51 0 c 0 13 0 15 0 15 L 0 15 Z"
                className="btCurveLeft"
              />
            </svg>
          </div>
          <div className="btCurveRightHolder">
            <svg
              version="1.1"
              id="Layer_4"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="50px"
              height="14px"
              viewBox="0 0 50 14"
              enableBackground="new 0 0 50 14"
              xmlSpace="preserve"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 50 14 c -27 0 -20 -14 -50 -14 c 0 13 0 14 0 145 L 50 14 Z"
                className="btCurveRight"
              />
            </svg>
          </div>
          <div className="btSiteFooterCurveSleeve"></div>
        </div>
      </section>
      <section className="boldSection btSiteFooterWidgets gutter topSemiSpaced topSemiSpaced btDoubleRowPadding">
        <div className="container-fluid rowItem">
          <div className="port">
            <ScrollToTop />
            <div className="row">
              <div className="col-lg-4 col-md-8 col-sm-12">
                <div className=" footer-p ">
                  <span className="pb-4">
                    <img
                      src="./assets/images/logo.png"
                      alt="Obit"
                      style={{ width: "150px" }}
                    />
                  </span>

                  <p className="mt-5">
                  Obit has raised the bar for the whole industry, making us the first choice for all aspiring
                    clients around the globe.
                  </p>
                  <p className="">
                    Our goal is to reach every isolated location, as part of our
                    global expansion and service provision.
                  </p>

                  {/* Footer Social icons */}
                  {/* <div className="row mb-4">
                    <div className="topTools btTextLeft">
                      <div className="f-social-media-section">
                        {socialMediaItem?.map((item, index) => (
                          <span key={index}>
                            <a
                              href={item.link}
                              target={`${item.link == "" ? "" : "_blank"}`}
                            >
                              <i className={`social-media ${item.icon}`}></i>
                            </a>
                          </span>
                        ))}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-lg-7 col-md-12">
                {navLink?.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                    key={index}
                  >
                    <div className="btBox widget_nav_menu" style={{paddingBottom:"21px"}}>
                      <h4 className="mt-0 p-0">
                        <span>{item.title}</span>
                      </h4>

                      <div className="menu-services-footer-container">
                        <ul id="menu-services-footer" className="menu">
                          {item?.linkList?.map((data, index) => (
                            <li
                              id="menu-item-1145"
                              key={index}
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1145"
                            >
                              <Link to={data.linkUrl} onClick={linkPageScroll}>
                                {data.linkName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" style={{paddingTop:"-70px"}}>
                  <div className="widget_nav_menu mt-0 p-0">
                    <div className="row footer-playstore-icon">
                      <span className="d-lg-flex d-md-flex d-sm-block d-xs-block">
                        <img
                          src="./assets/images/google-playstore1.png"
                          alt="Goole Play Store"
                        />
                        <img
                          src="./assets/images/ios-app-store.png"
                          alt="Google App Store"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <footer className="boldSection gutter btSiteFooter btGutter">
                <div className="port">
                  <div className="boldRow mt-3 pb-2">
                    <div className="rowItem btFooterCopy f-copywrite col-md-12 col-sm-12 btTextLeft d-md-flex d-sm-block justify-content-between">
                      <p className="copyLine">
                        Privacy Policy &nbsp;&nbsp;&nbsp; Terms & Conditions
                      </p>
                      <p className="text-right">
                        Copyright © 2023 OBIT Solutions.
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <Link
                          to="https://victoriousinfotech.com/"
                          className=""
                          target="_blank"
                        >
                          Design & Developed by Victoriuos Infotech.
                        </Link> */}
                      </p>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
